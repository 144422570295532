import React from "react";
import SvgComponent from "../tools/svg";
import {graphql} from "gatsby";

const IconContentComponent = ({icon, title, description}) => {
    return (
        <div className="primary text-start border-radius-lg row align-items-center">
            <div className="col-lg-1 text-center text-md-start">
                <SvgComponent url={icon}/>
            </div>
            <div className="col-lg-11 text-center text-md-start">
                <span className="h4 mt-3">{title}</span>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default IconContentComponent;

export const query = graphql`
  fragment IconContentFields on MarkdownRemarkFrontmatter {
    iconContents {
      image
      title
      description
    }
  }
`;
