import React from "react";

import SvgComponent from "./svg";
import WaveWhites from "../../assets/img/shapes/waves-white.svg";
import WaveDown from "../../assets/img/shapes/wave-down.svg";
import WaveUp from "../../assets/img/shapes/wave-up.svg";

const WavySection = ({children}) => (
    <section className="bg-gradient-primary position-relative overflow-hidden">
        <img src={WaveWhites} alt="pattern-lines" className="position-absolute opacity-6"/>
        <div className="position-absolute w-100 z-inde-1 top-0 mt-n3 negative-top-1">
            <SvgComponent url={WaveDown}/>
        </div>
            {children}
        <div className="position-absolute w-100 bottom-0 negative-bottom-1">
            <SvgComponent url={WaveUp}/>
        </div>
    </section>
)

export default WavySection;
