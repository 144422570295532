import {graphql, Link} from "gatsby";
import React from "react";
import SvgComponent from "../tools/svg";
import MovingWaves from "../../assets/img/moving-waves.svg";
import WavySection from "../tools/wavySection";
import showdown from 'showdown';

const converter = new showdown.Converter();

const UseCasesComponent = ({iconContents, card}) => {
    return (
        <WavySection>
            <div className="container pt-7 pt-md-5 pb-7">
                <div className="row align-items-center pt-5 pb-5">
                    <div className="col-lg-8">
                        {iconContents.map((iconContent, index) => {
                            if (index % 2 !== 0) return null;

                            const nextItem = iconContents[index + 1];

                            return(
                                <div key={index} className="row justify-content-start mb-4">
                                    <div className="col-md-6">
                                        <div className="info text-center">
                                            <div className="icon icon-md">
                                                <SvgComponent url={iconContent.image}/>
                                            </div>
                                            <hr className={"my-1"}/>
                                            <h5 className="text-white">{iconContent.title}</h5>
                                            <p className="text-white opacity-8" dangerouslySetInnerHTML={{__html: converter.makeHtml(iconContent.description)}}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <div className="info">
                                            <div className="icon icon-md">
                                                <SvgComponent url={nextItem.image}/>
                                            </div>
                                            <hr className={"my-1"}/>
                                            <h5 className="text-white">{nextItem.title}</h5>
                                            <p className="text-white opacity-8" dangerouslySetInnerHTML={{__html: converter.makeHtml(nextItem.description)}}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="col-lg-4 ms-auto">
                        <div className="card mb-lg-5 text-center">
                            <img className="card-img-top" src={card.image} alt={card.title} />
                            <div className="position-relative overflow-hidden" style={{height: "50px", marginTop:"-50px"}}>
                                <div className="position-absolute w-100 top-0 z-index-1">
                                    <SvgComponent url={MovingWaves} />
                                </div>
                            </div>
                            <div className="card-body">
                                <h4>
                                    {card.title}
                                </h4>
                                <p dangerouslySetInnerHTML={{__html: converter.makeHtml(card.description)}}/>
                            </div>
                            <Link to={card.reference} className=" mx-2 btn bg-primary text-white btn-block">{card.link}
                                <i className="fas fa-arrow-right text-xs ms-1"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </WavySection>
    )
}

export default UseCasesComponent;

export const query = graphql`
  fragment UseCasesFields on MarkdownRemarkFrontmatter {
    useCases {
      iconContents {
        image
        title
        description
      }
      card {
        image
        title
        description
        link
        reference
      }
    }
  }
`;
