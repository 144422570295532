import {graphql} from "gatsby";
import React from "react";
import Waves from "../../assets/img/shapes/waves-white.svg";
import showdown from 'showdown'

const converter = new showdown.Converter()

const QuoteComponent = ({image, quote, name, job, number, description, link, reference}) => {
    const content = converter.makeHtml(quote);

    return (
        <section className="py-4 position-relative overflow-hidden">
            <img src={Waves} alt="pattern-lines" className="position-absolute top-0 opacity-6"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-10 mx-auto bg-gradient-dark border-radius-lg">
                            <div className="row py-5">
                                <div className="col-xl-4 col-md-6 px-5 position-relative text-center text-md-start">
                                    <img className="img border-radius-md max-width-300 w-100 position-relative z-index-2"
                                         src={image} alt={name} />
                                </div>
                                <div className="col-xl-4 col-md-5 z-index-2 position-relative px-3 px-md-3 my-md-auto mt-4 text-center text-md-start">
                                    <p className="text-lg text-white">
                                        <span dangerouslySetInnerHTML={{__html: content}}/>
                                    </p>
                                    <p className="text-white font-weight-bold text-sm">{name} <span
                                        className="text-xs font-weight-normal">{job}</span></p>
                                    <hr className="vertical start-100 ms-n5 d-xl-block d-none"/>
                                </div>
                                <div className="col-1"></div>
                                <div className="col-xl-2 col-12 px-xl-0 px-5 my-xl-auto text-center text-xl-start">
                                    <h3 className="text-white mt-xl-0 mt-5">{number}</h3>
                                    <p className="text-sm text-white opacity-8" dangerouslySetInnerHTML={{__html: converter.makeHtml(description)}}/>
                                    <a href="#" className="text-primary icon-move-right text-sm">
                                        {link}
                                        <i className="fas fa-arrow-right text-xs"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default QuoteComponent;

export const query = graphql`
  fragment QuoteFields on MarkdownRemarkFrontmatter {
    quote {
      image
      quote
      name
      job
      number
      description
      link
    }
  }
`;
