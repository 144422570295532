import * as React from "react"

import LayoutComponent from "../components/layout";
import {graphql} from "gatsby";

import IconContentList from "../components/fields/iconContentList";
import HomeHeaderComponent from "../components/fields/home/header";
import QuoteComponent from "../components/fields/quote";
import MetadataComponent from "../components/fields/metadata";
import ActivityComponent from "../components/fields/activity";
import UseCasesComponent from "../components/fields/useCases";
import CallToActionComponent from "../components/fields/callToAction";
import {useEffect, useState} from "react";
import { client } from '../../tina/__generated__/client';
import ActualityComponent from "../components/actuality";
import RealisationsComponent from "../components/realisations";


const IndexPage = ({data}) => {
    const [realisations, setRealisations] = useState([]);
    const {frontmatter} = data.markdownRemark;

    useEffect(() => {
        async function retrieveRealisations() {
            let reals = await client.queries.clientsConnection();

            if (typeof reals.data.clientsConnection.edges !== 'undefined') {
                setRealisations(reals.data.clientsConnection.edges)
            }
        }

        if(realisations.length == 0) {
            retrieveRealisations();
        }
    })


    return (<>
            <MetadataComponent
                description={frontmatter.metadata.description}
                title={frontmatter.metadata.title}
                keywords={frontmatter.metadata.keywords}
                code={frontmatter.metadata.code}
            />

            <LayoutComponent activeCategory={"home"}>
                <HomeHeaderComponent
                    title={frontmatter.header.title}
                    subtitle={frontmatter.header.subtitle}
                    description={frontmatter.header.description}
                    image={frontmatter.header.image}
                    alert={frontmatter.header.alert}
                />

                <ActualityComponent/>

                <section className="pb-md-7 pt-md-3">
                    <div className="container">
                        <IconContentList iconContents={frontmatter.iconContents} />
                    </div>
                </section>

                <QuoteComponent
                    quote={frontmatter.quote.quote}
                    name={frontmatter.quote.name}
                    job={frontmatter.quote.job}
                    link={frontmatter.quote.link}
                    description={frontmatter.quote.description}
                    image={frontmatter.quote.image}
                    number={frontmatter.quote.number}
                />

                <ActivityComponent
                    title={frontmatter.activity.title}
                    subtitle={frontmatter.activity.subtitle}
                    description={frontmatter.activity.description}
                    link={frontmatter.activity.link}
                    reference={frontmatter.activity.reference}
                    iconContents={frontmatter.activity.iconContents}
                />

                <UseCasesComponent
                    iconContents={frontmatter.useCases.iconContents}
                    card={frontmatter.useCases.card}
                />

                {/*<RealisationsComponent
                    title={frontmatter.realisations.title}
                    subtitle={frontmatter.realisations.subtitle}
                    description={frontmatter.realisations.description}
                    realisations={realisations}
                />*/}

                <RealisationsComponent nbRealisations={6}/>

                <CallToActionComponent
                    title={frontmatter.callToAction.title}
                    link={frontmatter.callToAction.link}
                    reference={frontmatter.callToAction.reference}
                    image={frontmatter.callToAction.image}
                />

            </LayoutComponent>
        </>

    )
}

export default IndexPage

export const pageQuery = graphql`
query {
  markdownRemark(frontmatter: {metadata: {code: {eq: "home"}}}) {
      frontmatter {
        ...MetadataFields
        ...HeaderFields
        ...IconContentFields
        ...QuoteFields
        ...ActivityFields
        ...UseCasesFields
        ...CallToActionFields
        ...RealisationsFields
      }
    
  }
}
`
