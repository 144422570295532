import React from "react";
import IconContentComponent from "./iconContent";

const IconContentList = ({iconContents}) => {
    return (
        <div className="row justify-content-start">
            {iconContents.map((item, index) => {
                if (index % 2 !== 0) return null;

                const nextItem = iconContents[index + 1];

                return (
                    <>
                        <div key={index} className="col-md-6">
                            <IconContentComponent icon={item.image} title={item.title} description={item.description}/>
                            <hr className={"my-3"}/>
                            {nextItem && <IconContentComponent icon={nextItem.image} title={nextItem.title} description={nextItem.description}/>}
                        </div>
                        <hr className={"my-3 d-md-none"}/>
                    </>
                );
            })}
        </div>
    )
}

export default IconContentList;
