import React from "react";
import {useEffect, useState} from "react";
import Glide, {Controls, Swipe} from "@glidejs/glide/dist/glide.modular.esm";
import ArrowLeft from "../assets/img/arrow-left.svg";
import ArrowRight from "../assets/img/arrow-right.svg";
import SvgComponent from "./tools/svg";

const ActualityComponent = () => {
    const [actuality, setActuality] = useState([]);
    const notionBaseUrl = 'https://notionproxyserver.netlify.app/api/notion';
    const notionDatabaseId = 'd9b8dee3-233e-4ea7-b144-7ac2290e546c';
    const notionEndpoint = 'databases/' + notionDatabaseId + '/query';

    useEffect(() => {
        async function retrieveActuality() {
            const response = await fetch(notionBaseUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    "body": {
                        "filter": {
                            "and": [
                                {
                                    "property": "status",
                                    "select": {
                                        "equals": "Postée"
                                    }
                                },
                                {
                                    "property": "generationType",
                                    "select": {
                                        "equals": "actualité"
                                    }
                                }
                            ],
                        },
                        "page_size": 3
                    },
                    "endpoint": notionEndpoint
                })
            });
            const data = await response.json();
            let actualities = [];
            for (let i = 0; i < data.results.length; i++) {
                actualities.push(new Actuality(data.results[i]));
            }
            setActuality(actualities);
        }

        if(actuality.length === 0) {
            retrieveActuality();
        } else {
            for (let i = 0; i < actuality.length; i++) {
                let glideId = "#" + generateIdFromTitle(actuality[i].title) + "Glide";
                let glide = new Glide(glideId, {
                    type: "slider",
                    perView: 2
                });
                glide.mount({ Controls, Swipe });
            }
        }
    })

    const generateIdFromTitle = (title) => {
        let id = title.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        id = id.replace(/\s/g, '-');
        return id;
    }

    let columns = (12 / actuality.length);

    const displayImage = (image) => {
        let fileType = image['type'];
        return <img src={image[fileType]['url']} alt={"Image du post "} className="img-fluid"/>;
    }

    return (
        <div className="container mb-5">
            <div className="row">
                <h1 className="text-center display-1 mb-5">Dernières actualités</h1>
                {actuality.map((actuality, index) => {
                    return (
                        <>
                            <div className={`col-lg-${columns} col-md-${columns} col-sm-${columns} col-12`} key={index}>
                                <div className="card move-on-hover mb-4">
                                    <div className="card-header">
                                        <img className={"img-fluid"} src={actuality.imageUrl}
                                             alt={"Image du post : " + actuality.title}/>
                                        {/*<span
                                            className="badge rounded-pill badge-primary mt-2">{actuality.updatedAt.toLocaleDateString('fr-FR')}</span>*/}
                                    </div>
                                    <div className="card-body py-0 ">
                                        <h4 className="">{actuality.title.substring(0, 25) + (actuality.title.length > 25 ? "..." : "")}</h4>
                                        <p className={'mb-0'}>{actuality.description.substring(0, 100) + "..."}</p>
                                    </div>
                                    <div className="card-footer text-center">
                                        <button className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target={"#" + generateIdFromTitle(actuality.title)}>Lire la suite</button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id={generateIdFromTitle(actuality.title)} tabIndex="-1" role="dialog"
                                 aria-labelledby={generateIdFromTitle(actuality.title) + "Label"} aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <div id={generateIdFromTitle(actuality.title) + "Glide"} className="glide" data-glide-el="carousel">
                                                <div className="glide__track" data-glide-el="track">
                                                    <ul className="glide__slides w-50">
                                                        <li className="glide__slide">
                                                            <img src={actuality.imageUrl}
                                                                 alt={"Image du post : " + actuality.title}
                                                                 className="img-fluid"/>
                                                        </li>
                                                        {actuality.allImages.length > 0 ? actuality.allImages.map((image, index) => {
                                                            return <li className="glide__slide" key={index}>
                                                                {displayImage(image)}
                                                            </li>
                                                        }) : null}
                                                    </ul>
                                                </div>

                                                <div className="glide__arrows" data-glide-el="controls">
                                                    <button className="glide__arrow glide__arrow--left "
                                                            data-glide-dir="<"><SvgComponent url={ArrowLeft}/>
                                                    </button>
                                                    <button className="glide__arrow glide__arrow--right"
                                                            data-glide-dir=">"><SvgComponent url={ArrowRight}/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <h5 className="modal-title mb-3"
                                                id={generateIdFromTitle(actuality.title) + "Label"}>{actuality.title}</h5>
                                            <hr/>
                                            <div
                                                dangerouslySetInnerHTML={{__html: actuality.description.replace(/\n/g, "<br>")}}/>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn bg-gradient-secondary"
                                                    data-bs-dismiss="modal">Fermer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
        </div>
    );
}

export default ActualityComponent;

const Actuality = class {
    constructor(data) {
        let fileType = data['properties']['images']['files'][0]['type'];
        this.title = data['properties']['title']['title'][0]['plain_text'];
        this.description = data['properties']['description']['rich_text'][0]['plain_text'];
        this.hashtags = data['properties']['hashtags']['rich_text'][0]['plain_text'];
        this.imageUrl = data['properties']['images']['files'][0][fileType]['url'];
        this.allImages = data['properties']['images']['files'];
        this.allImages.shift();
        this.updatedAt = new Date(data['properties']['updatedAt']['last_edited_time']);
        this.type = data['properties']['generationType']['select']['name'];
    }
}