import {graphql, Link} from "gatsby";
import React from "react";
import showdown from 'showdown'

const converter = new showdown.Converter()

const ActivityComponent = ({title, subtitle, description, link, reference, iconContents}) => {
    const content = converter.makeHtml(description);

    return (
        <section className="py-sm-7 py-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 text-center text-md-left">
                        <h3 className="display-4 text-gradient text-primary mb-0 mt-2 mb-2">{title}</h3>
                        <h3 className={"display-6 mb-4"}>{subtitle}</h3>
                        <span dangerouslySetInnerHTML={{__html: content}} />
                    </div>
                    <div className="col-lg-6 mt-lg-0 mt-1 ps-lg-0 ps-0">
                        {iconContents.map((iconContent, index) => (
                            <div key={index} className="p-3 info-horizontal row align-items-center">
                                <div className="text-center col-md-1">
                                    <img src={iconContent.image} alt={iconContent.description}/>
                                </div>
                                <div className="description text-center text-md-start ps-3 col-md-11">
                                    <p className="mb-0" dangerouslySetInnerHTML={{__html: converter.makeHtml(iconContent.description)}}/>
                                </div>
                            </div>
                        ))}

                        <Link to={reference} className="d-none d-md-block mt-3 mx-7 btn btn-secondary">{link}
                            <i className="fas fa-arrow-right text-sm ms-1"/>
                        </Link>
                    </div>
                    <Link to={reference} className="d-block d-md-none mt-3 btn btn-secondary">{link}
                        <i className="fas fa-arrow-right text-sm ms-1"/>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default ActivityComponent;

export const query = graphql`
  fragment ActivityFields on MarkdownRemarkFrontmatter {
    activity {
      title
      subtitle
      description
      link
      reference
      iconContents {
        image
        description
      }
    }
  }
`;
